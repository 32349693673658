import {BACKEND, AUTH_BACKEND} from '../config';
import {http} from '../utils/http';
import {getUseSharedReducer} from '../utils/getUseSharedReducer';
import {handleHttpError} from './error';

export const AUTH_ACTIONS = Object.freeze({
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_READY: 'SET_READY'
});

const initialAuthState = {
    profile: null,
    account: null,
    ready: true
};

function reducer(state, action) {
    switch (action.type) {
    case AUTH_ACTIONS.LOGIN:
        return {
            ...action.payload,
            ready: true
        };

    case AUTH_ACTIONS.LOGOUT:
        return initialAuthState;

    case AUTH_ACTIONS.SET_READY:
        return {
            ...state,
            ready: action.payload
        };

    default:
        return state;
    }
}

export const useAuth = getUseSharedReducer(reducer, initialAuthState);

export function getAuth() {
    useAuth.dispatch({
        type: AUTH_ACTIONS.SET_READY,
        payload: false
    });

    return Promise.all([
        http.get(`${BACKEND}/`),
        http.get(`${BACKEND}/profile`)
    ])
        .then(([account, profile]) => {
            useAuth.dispatch({
                type: AUTH_ACTIONS.LOGIN,
                payload: {account, profile}
            });
            return {account, profile};
        })
        .catch(handleHttpError)
        .finally(() => useAuth.dispatch({
            type: AUTH_ACTIONS.SET_READY,
            payload: true
        }));
}

export function patchProfile(profile) {
    useAuth.dispatch({
        type: AUTH_ACTIONS.SET_READY,
        payload: false
    });

    // TODO: заменить на реальный запрос, когда появится бэк
    return new Promise(resolve => {
        setTimeout(
            () => resolve(profile),
            1000
        );
    })
        .then(() => getAuth())
        .catch(handleHttpError)
        .finally(() => useAuth.dispatch({
            type: AUTH_ACTIONS.SET_READY,
            payload: true
        }));
}

export function patchProfileSettings(settings) {
    useAuth.dispatch({
        type: AUTH_ACTIONS.SET_READY,
        payload: false
    });

    return http.patch(`${BACKEND}/profile`, {personal_settings: settings})
        .then(() => useAuth.getState().profile.personal_settings?.lang == settings.lang
            ? getAuth()
            : window.location.reload()
        )
        .catch(handleHttpError)
        .finally(() => useAuth.dispatch({
            type: AUTH_ACTIONS.SET_READY,
            payload: true
        }));
}

export function login(loginData) {
    useAuth.dispatch({
        type: AUTH_ACTIONS.SET_READY,
        payload: false
    });

    return http.post(`${AUTH_BACKEND}/login`, loginData)
        .then(result => {
            getAuth();
            return result;
        })
        .catch(({error}) => {
            useAuth.dispatch({type: AUTH_ACTIONS.LOGOUT});
            throw error;
        })
        .finally(() => useAuth.dispatch({
            type: AUTH_ACTIONS.SET_READY,
            payload: true
        }));
}

export function logout() {
    useAuth.dispatch({
        type: AUTH_ACTIONS.SET_READY,
        payload: false
    });

    return http.post(`${AUTH_BACKEND}/logout`)
        .finally(() => useAuth.dispatch({type: AUTH_ACTIONS.LOGOUT}));
}

// TODO: удалить, когда появится бэк
const stats = [
    {
        id: 0,
        type: 'quality',
        link: '#',
        text: '[music] Людмила Васильевна, [music] [fil] [noise] [sil] [spam] [spk] вечер добрый! группы компании ' +
            'пик, [fil] меня зовут Игорь, а ранее с вами общались по вопросу [noise] приобретения недвижимости. ' +
            'удобно сейчас разговаривать?',
        done: false,
        timestamp: new Date(),
        favourite: true,
        recordLength: 3467842
    },
    {
        id: 1,
        type: 'voice',
        link: '#',
        text: '[music] Людмила Васильевна, [music] [fil] [noise] [sil] [spam] [spk] вечер добрый! группы компании ' +
            'пик, [fil] меня зовут Игорь, а ранее с вами общались по вопросу [noise] приобретения недвижимости. ' +
            'удобно сейчас разговаривать?',
        done: false,
        timestamp: new Date(),
        favourite: true,
        recordLength: 3467842
    }
];

export function getUserStatistics(id, queryParams) {
    // TODO: заменить на реальный запрос, когда появится бэк
    return new Promise(resolve => {
        setTimeout(
            () => resolve({
                tasks: new Array(5).fill(stats)
                    .flat()
                    .map((task, index) => ({
                        ...task,
                        id: index,
                        name: `Test task #${queryParams.offset + index}`
                    })),
                total: 10 + Math.random() * 1000,
                stats: {
                    quality: 10 + Math.round(Math.random() * 100),
                    voice: 10 + Math.round(Math.random() * 100)
                }
            }),
            2000
        );
    })
        .catch(handleHttpError);
}

export function restorePassword(username) {
    // TODO: заменить на реальный запрос, когда появится бэк
    return new Promise(resolve => {
        setTimeout(
            () => resolve(username),
            1000
        );
    })
        .catch(handleHttpError);
}

export function resetPassword(password, token) {
    // TODO: заменить на реальный запрос, когда появится бэк
    return new Promise(resolve => {
        setTimeout(
            () => resolve(password, token),
            1000
        );
    })
        .catch(handleHttpError);
}
