import {AUTH_ACTIONS, useAuth} from './user';

export const ERROR_CODES = {
    accessDenied: 11,
    somethingGoneWrong: -1000
};

export function handleHttpError(httpResponse) {
    const error = httpResponse?.error || {};
    const code = error.code || ERROR_CODES.somethingGoneWrong;

    if (code == ERROR_CODES.accessDenied)
        useAuth.dispatch({type: AUTH_ACTIONS.LOGOUT});

    throw {
        ...error,
        code
    };
}

