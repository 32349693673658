import React from 'react';
import ReactDOM from 'react-dom';

import 'reset-css';
import './styles/index.scss';

import App from './components/App';
import OverallProgress from './components/OverallProgress';
import {getAuth} from './services/user';
import {showLoginErrorPopup} from './services/popup';

const rootElement = document.querySelector('#root');

ReactDOM.render(
    <OverallProgress size={86}/>,
    rootElement
);

getAuth()
    .catch(showLoginErrorPopup)
    .catch(Boolean)
    .then(() => {
        ReactDOM.render(
            <React.StrictMode>
                <App/>
            </React.StrictMode>,
            rootElement
        );
    });
