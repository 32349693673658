import {getUrl} from './url';

const defaultSettings = {
    mode: 'cors',
    credentials: 'include'
};

function request(url, searchParams = {}, settings = defaultSettings) {
    url = getUrl(url, searchParams);

    return fetch(url, settings)
        .then(response => response.json()
            .then(result => ({result, ok: response.ok}))
            .catch(() => ({result: {}, ok: response.ok}))
        )
        .then(({result, ok}) => ok
            ? result
            : throw {error: result}
        );
}

function get(url, searchParams = {}, settings = defaultSettings) {
    return request(url, searchParams, {...settings, method: 'GET'});
}

function patch(url, body = {}, searchParams = {}, settings = defaultSettings) {
    const requestData = {
        ...settings,
        body: body instanceof FormData
            ? body
            : JSON.stringify(body),
        method: 'PATCH'
    };

    setRequestContentType(requestData);

    return request(url, searchParams, requestData);
}

function post(url, body = {}, searchParams = {}, settings = defaultSettings) {
    const requestData = {
        ...settings,
        body: body instanceof FormData
            ? body
            : JSON.stringify(body),
        method: 'POST'
    };

    setRequestContentType(requestData);

    return request(url, searchParams, requestData);
}

function put(url, body = {}, searchParams = {}, settings = defaultSettings) {
    const requestData = {
        ...settings,
        body: body instanceof FormData
            ? body
            : JSON.stringify(body),
        method: 'PUT'
    };

    setRequestContentType(requestData);

    return request(url, searchParams, requestData);
}

function setRequestContentType(requestData) {
    const contentType = requestData.body instanceof FormData
        ? undefined
        : 'application/json';

    if (contentType) {
        requestData.headers = requestData.headers || {};
        requestData.headers['Content-Type'] = requestData.headers['Content-Type'] || contentType;
    }
}

function _delete(url, searchParams = {}, settings = defaultSettings) {
    return request(url, searchParams, {...settings, method: 'DELETE'});
}

export const http = {
    request,
    get,
    patch,
    post,
    put,
    delete: _delete
};
