import {Box} from '@material-ui/core';

import Progress from './Progress';

export default function OverallProgress(props) {
    return (
        <Box
            position='absolute'
            left={0}
            top={0}
            width={1}
            height={1}
            display='flex'
            justifyContent='center'
            alignItems='center'
        >
            <Progress {...props}/>
        </Box>
    );
}
