const CONFIG = process.env.NODE_ENV == 'production'
    ? {
        BACKEND: 'https://app.touchpoint-analytics.xyz/api',
        AUTH_BACKEND: 'https://auth.touchpoint-analytics.xyz'
    }
    : {
        BACKEND: 'https://app.touchpoint-analytics.xyz/api',
        AUTH_BACKEND: 'https://auth.touchpoint-analytics.xyz'
    };

export const {AUTH_BACKEND, BACKEND} = CONFIG;
