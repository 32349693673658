import {createMuiTheme} from '@material-ui/core/styles';
import {blue, grey, green, teal, deepOrange, amber, cyan, deepPurple} from '@material-ui/core/colors';

export const defaultTheme = createMuiTheme({
    palette: {
        primary: {
            light: blue[50],
            main: blue[500],
            dark: blue[800],
            text: grey[900]
        },
        secondary: {
            light: teal[200],
            main: teal[500],
            dark: teal[900]
        },
        grey: {
            light: grey[100],
            main: grey[400],
            dark: grey[700]
        },
        status: {
            warning: amber[500],
            error: deepOrange[800],
            success: green[500],
            default: grey[500],
            message: blue[800]
        },
        task: {
            voice: deepPurple[400],
            quality: cyan[200]
        },
        text: {
            primary: grey[800]
        },
        borderColor: '#0000003b',
        backgroundApp: '#eaf2fd'
    },
    pageMaxWidth: 1400,
    sideWidth: 222,
    typography: {
        h1: {
            fontSize: '2rem',
            fontWeight: 'bolder'
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 'bolder'
        },
        h3: {
            fontSize: '1.17rem',
            fontWeight: 'bolder'
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 'bolder'
        },
        h5: {
            fontSize: '0.83rem',
            fontWeight: 'bolder'
        },
        h6: {
            fontSize: '0.67rem',
            fontWeight: 'bolder'
        },
        subtitle1: {
            color: grey[500],
            fontSize: 16,
            fontWeight: 400
        }
    },
    overrides: {
        MuiOutlinedInput: {
            notchedOutline: {
                '& legend': {
                    maxWidth: 1000
                }
            }
        },
        MuiDialogActions: {
            root: {
                paddingRight: 24
            }
        }
    }
});
