import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';
import {defaultTheme} from '~/themes/themes';

import PrivateRoute from './PrivateRoute';
import Popups from './Popups';
import OverallProgress from './OverallProgress';
import Box from '@material-ui/core/Box';

const Login = React.lazy(() => import('./Login'));
const Main = React.lazy(() => import('./Main'));
const Header = React.lazy(() => import('./Header'));
const SidebarTasks = React.lazy(() => import('./SidebarTasks'));
const SidebarEnrichment = React.lazy(() => import('./SidebarEnrichment'));
const TaskManager = React.lazy(() => import('./TaskManager'));
const TaskRouter = React.lazy(() => import('./TaskRouter'));
const PhraseManager = React.lazy(() => import('./PhraseManager'));
const WordManager = React.lazy(() => import('./WordManager'));
const RestorePassword = React.lazy(() => import('./RestorePassword'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));

export default function App() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <div className='app'>
                <Router>
                    <Suspense fallback={<OverallProgress size={86}/>}>
                        <Switch>
                            <Route exact path='/login' render={() => <Login/>}/>
                            <Route path='/login/password/restore' render={() => <RestorePassword/>}/>
                            <Route path='/login/password/reset' render={() => <ResetPassword/>}/>
                            <PrivateRoute>
                                <Route path='/' render={() => <Header/>}/>
                                <Box
                                    flex={1}
                                    width={1}
                                    bgcolor='white'
                                    mx='auto'
                                    maxWidth={defaultTheme.pageMaxWidth}
                                    display='flex'
                                    className='page page__wrapper'
                                >
                                    <Route exact path='/' render={() => <Main/>}/>
                                    <Route path='/tasks' render={() => <SidebarTasks/>}/>
                                    <Route exact path='/tasks' render={() => <TaskManager/>}/>
                                    <Route exact path='/tasks/:id' render={() => <TaskRouter/>}/>
                                    <Route path='/enrichment' render={() => <SidebarEnrichment/>}/>
                                    <Route path='/enrichment/phrases' render={() => <PhraseManager/>}/>
                                    <Route path='/enrichment/words' render={() => <WordManager/>}/>
                                </Box>
                            </PrivateRoute>
                        </Switch>
                    </Suspense>
                </Router>
                <Popups/>
            </div>
        </ThemeProvider>
    );
}
