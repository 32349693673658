import {useEffect, useState} from 'react';

export function getUseSharedReducer(reducer, initial) {
    let state = initial;
    const listeners = [];

    function subscribe(listener) {
        listeners.push(listener);

        return () => unsubscribe(listener);
    }

    function unsubscribe(listener) {
        const index = listeners.indexOf(listener);

        listeners.splice(index, 1);
    }

    function notify() {
        for (const listener of listeners)
            listener(state);
    }

    function dispatch(action) {
        const newState = reducer(state, action);

        if (newState !== state) {
            state = newState;
            notify();
        }
    }

    function getState() {
        return state;
    }

    function useSharedReducer() {
        const [_data, _setData] = useState(state);

        useEffect(
            () => subscribe(_setData),
            []
        );

        return [_data, dispatch];
    }

    useSharedReducer.dispatch = dispatch;
    useSharedReducer.subscribe = subscribe;
    useSharedReducer.getState = getState;

    return useSharedReducer;
}
