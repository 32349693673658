import {getUseSharedReducer} from '../utils/getUseSharedReducer';
import {formatError} from '../utils/formatters';
import {ERROR_CODES} from './error';

const DEFAULT_TIMEOUT = 5000;
let popupId = 0;

const POPUP_ACTIONS = Object.freeze({
    ADD: 'ADD',
    REMOVE: 'REMOVE',
    REMOVE_ALL: 'REMOVE_ALL'
});

function reducer(state, action) {
    switch (action.type) {
    case POPUP_ACTIONS.ADD:
        return [
            ...state,
            action.payload
        ];

    case POPUP_ACTIONS.REMOVE:
        return state.filter(({id}) => id != action.payload);

    case POPUP_ACTIONS.REMOVE_ALL:
        return [];

    default:
        console.error('unexpected action', action);
        return state;
    }
}

export const usePopups = getUseSharedReducer(reducer, []);

export function showPopup(popupData) {
    if (typeof popupData == 'string')
        popupData = {message: popupData};

    const message = popupData.message || popupData.error;
    const timeout = isFinite(popupData.timeout) ? popupData.timeout : DEFAULT_TIMEOUT;
    const timeoutId = timeout
        ? setTimeout(hideAll, timeout)
        : undefined;
    const type = popupData.type || (popupData.error
        ? 'error'
        : 'message'
    );

    Object.assign(popupData, {
        id: popupId++,
        message,
        type,
        timeoutId,
        close: hideAll
    });

    hideAll();
    usePopups.dispatch({type: POPUP_ACTIONS.ADD, payload: popupData});
}

export function showErrorPopup(error) {
    showPopup({error: formatError(error)});
    throw error;
}

export function showLoginErrorPopup(error) {
    if (error.code != ERROR_CODES.accessDenied)
        showErrorPopup(error);
}

function hideAll() {
    usePopups.dispatch({type: POPUP_ACTIONS.REMOVE_ALL});
}
