import {useLocation, useParams} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';

export function useLocationParams() {
    const path = useParams();
    const location = useLocation();
    const searchParams = useMemo(
        () => [...new URLSearchParams(location.search).entries()]
            .reduce(
                (paramMap, [key, value]) => {
                    let existingParam = paramMap[key];

                    if (existingParam) {
                        if (!Array.isArray(existingParam))
                            existingParam = paramMap[key] = [existingParam];

                        existingParam.push(value);
                    } else
                        paramMap[key] = value;

                    return paramMap;
                },
                {}
            ),
        [location]
    );
    const [params, setParams] = useState(getParams);

    function getParams() {
        return {
            path,
            search: searchParams
        };
    }

    useEffect(
        () => {
            setParams(getParams());
        },
        [path, location]
    );

    return params;
}

export function getUrl(url, searchParamMap = {}) {
    url = new URL(url);
    populateSearchParams(searchParamMap, url.searchParams);
    return `${url}`;
}

export function getSearchString(searchParamMap = {}) {
    return `?${populateSearchParams(searchParamMap)}`;
}

function populateSearchParams(searchParamMap, searchParams = new URLSearchParams) {
    return Object
        .entries(searchParamMap)
        .reduce(
            (searchParams, [name, value]) => {
                for (const singleValue of [].concat(value ?? []))
                    searchParams.append(name, singleValue);

                return searchParams;
            },
            searchParams
        );
}
