import locale from '../localization/ru.yaml';

export function trl(parts, ...params) {
    const key = getTranslationKey(parts);
    const translation = locale[key] || key;

    return translation.replace(/%(\d)/g, (_, number) => params[number]);
}

export function trlCount(parts, ...params) {
    const key = getTranslationKey(parts);
    const [actualCount] = params;
    let actualKey = key;

    if (Number.isInteger(actualCount)) {
        const leastSignificantDigit = actualCount % 10;
        const tens = (actualCount / 10 | 0) % 10;

        if (tens != 1)
            if (leastSignificantDigit == 1)
                actualKey += '.1';
            else if (leastSignificantDigit >= 2 && leastSignificantDigit < 5)
                actualKey += '.2';
    }

    const translation = locale[actualKey] || key;

    return translation.replace(/%(\d)/g, (_, number) => params[number]);
}

function getTranslationKey(parts) {
    return parts.raw
        ? parts.reduce((key, part, index) => {
            const paramPlaceHolder = index < parts.length - 1
                ? `%${index}`
                : '';
            return key + part + paramPlaceHolder;
        }, '')
        : parts;
}

export function trlObject(key) {
    return locale[key];
}
