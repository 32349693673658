import {trl, trlObject} from '../services/localization';

export const formatError = error => {
    const key = `_ошибка.${error.code}`;
    return trlObject(key)
        ? trl(key)
        : error.message;
};

export const formatDateTime = value => new Date(value)
    .toLocaleString(trl`_локаль`, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    });

export const formatDayMonth = value => new Date(value)
    .toLocaleString(trl`_локаль`, {
        month: 'long',
        day: '2-digit'
    });

export const formatMonthYear = value => new Date(value)
    .toLocaleString(trl`_локаль`, {
        year: 'numeric',
        month: 'long'
    });

