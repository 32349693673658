import {Box, IconButton, Snackbar, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {defaultTheme} from '~/themes/themes';
import {usePopups} from '~/services/popup';

function Popup({message, type, onClick}) {
    return (
        <Snackbar
            open
            ContentProps={{
                style: {
                    backgroundColor: defaultTheme.palette.status[type]
                }
            }}
            message={
                <Box
                    width={400}
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Typography
                        className='text text_overflow'
                        variant='h4'
                        title={message}
                    >
                        {message}
                    </Typography>
                    <IconButton size='small' onClick={onClick}>
                        <CloseIcon htmlColor='white'/>
                    </IconButton>
                </Box>
            }
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        />
    );
}

export default function Popups() {
    const [popups] = usePopups();

    return popups.map(({id, message, type, close}) =>
        <Popup
            key={id}
            message={message}
            type={type}
            onClick={close}
        />
    );
}
