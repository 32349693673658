import {useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuth} from '~/services/user';

export default function PrivateRoute({children, ...rest}) {
    const [{profile}] = useAuth();

    useEffect(() => console.log(profile), [profile]);

    return (
        <Route
            {...rest}
            render={({location}) =>
                profile
                    ? children
                    : <Redirect to={{pathname: '/login', state: {from: location}}}/>
            }
        />
    );
}
